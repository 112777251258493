import React from 'react';
import styled from 'styled-components';


export default () => {

    return (
        <>
            <section id="map">
                <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2773.8230433101803!2d23.297256503619582!3d42.66535728618178!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa84ee1718aa49%3A0xa697c7aae42c9b8d!2z0KDQtdGB0YLQvtGA0LDQvdGCINCQ0YDQuNCw0L3QsA!5e0!3m2!1sen!2suk!4v1606697425968!5m2!1sen!2suk" width="100%" height="500" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </section>
        </>
    )
}